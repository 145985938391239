import translation from "~/locales/ro-RO/translation.json";
import category from "~/locales/ro-RO/category.json";
import checkout from "~/locales/ro-RO/checkout.json";
import edit_profile from "~/locales/ro-RO/edit_profile.json";
import forgot_password from "~/locales/ro-RO/forgot_password.json";
import home from "~/locales/ro-RO/home.json";
import login from "~/locales/ro-RO/login.json";
import order_complete from "~/locales/ro-RO/order_complete.json";
import order from "~/locales/ro-RO/order.json";
import orders from "~/locales/ro-RO/orders.json";
import product from "~/locales/ro-RO/product.json";
import products from "~/locales/ro-RO/products.json";
import profile from "~/locales/ro-RO/profile.json";
import register from "~/locales/ro-RO/register.json";
import resetpassword from "~/locales/ro-RO/resetpassword.json";
import wishlist from "~/locales/ro-RO/wishlist.json";
import newsletter from "~/locales/ro-RO/newsletter.json";

import entranslation from "~/locales/en-US/translation.json";
import encategory from "~/locales/en-US/category.json";
import encheckout from "~/locales/en-US/checkout.json";
import enedit_profile from "~/locales/en-US/edit_profile.json";
import enforgot_password from "~/locales/en-US/forgot_password.json";
import enhome from "~/locales/en-US/home.json";
import enlogin from "~/locales/en-US/login.json";
import enorder_complete from "~/locales/en-US/order_complete.json";
import enorder from "~/locales/en-US/order.json";
import enorders from "~/locales/en-US/orders.json";
import enproduct from "~/locales/en-US/product.json";
import enproducts from "~/locales/en-US/products.json";
import enprofile from "~/locales/en-US/profile.json";
import enregister from "~/locales/en-US/register.json";
import enresetpassword from "~/locales/en-US/resetpassword.json";
import enwishlist from "~/locales/en-US/wishlist.json";
import ennewsletter from "~/locales/en-US/newsletter.json";

export const defaultNS = "translation";
export const resources = {
  "ro-RO": {
    translation,
    category,
    checkout,
    edit_profile,
    forgot_password,
    home,
    login,
    order_complete,
    order,
    orders,
    product,
    products,
    profile,
    register,
    resetpassword,
    wishlist,
    newsletter,
  },
  "en-US": {
    translation: entranslation,
    category: encategory,
    checkout: encheckout,
    edit_profile: enedit_profile,
    forgot_password: enforgot_password,
    home: enhome,
    login: enlogin,
    order_complete: enorder_complete,
    order: enorder,
    orders: enorders,
    product: enproduct,
    products: enproducts,
    profile: enprofile,
    register: enregister,
    resetpassword: enresetpassword,
    wishlist: enwishlist,
    newsletter: ennewsletter
  },
} as const;

export default {
  // This is the list of languages your application supports
  supportedLngs: ["en-US", "ro-RO"],
  // This is the language you want to use in case
  // if the user language is not in the supportedLngs
  fallbackLng: "ro-RO",
  resources,
  defaultNS,
};
